import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const Toast = ({
  success,
  error,
  icon,
}) => {
  const key = success ? 'success' : 'error';
  const showToast = toast[key];

  return showToast(
    <>
      <FontAwesomeIcon
        className="mr-2"
        icon={
          icon ? icon :
          !!success ? faCheckCircle : faTimesCircle
        }
      />
      {success || error}
    </>
  );
};

export default Toast;