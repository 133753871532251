import React from 'react';
import styled from 'styled-components';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Logo } from '../images';
import { withAuth, withFirebase } from '../providers';

const Image = styled.img`
  width: 50px;
`;

const MainNav = ({
  firebase,
  user
}) => (
  <Navbar bg="dark" variant="dark" expand="lg">
    <Navbar.Brand as={Link} to="/">
      <Image src={Logo} alt="On The Pass" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="main-nav" className="border-0" />
    <Navbar.Collapse id="main-nav">
      <Nav className="ml-auto">
        <NavDropdown alignRight title={user.name || user.email} id="user">
          {!!user.roles['admin'] &&
            <>
              <NavDropdown.Item as={Link} to="/admin">Admin</NavDropdown.Item>
              <NavDropdown.Divider />
            </>
          }

          <NavDropdown.Item onClick={() => firebase.signOut()}>Sign Out</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default compose(
  withAuth,
  withFirebase,
)(MainNav);