export const WAIT_TIMES_KEY = 'wait_times';

export const getWaitTimes = (enterpriseId, profitCenterId) => {
  const waitTimes = JSON.parse(localStorage.getItem(WAIT_TIMES_KEY) || null);
  if (waitTimes && waitTimes[enterpriseId] && waitTimes[enterpriseId][profitCenterId]) {
    const curWaitTimes = waitTimes[enterpriseId][profitCenterId];
    return curWaitTimes;
  }

  return null;
}

export const setWaitTimes = (enterpriseId, profitCenterId, times) => {
  const waitTimes = JSON.parse(localStorage.getItem(WAIT_TIMES_KEY) || '{}');

  const newWaitTimes = {
    ...waitTimes,
    [enterpriseId]: {
      ...waitTimes[enterpriseId],
      [profitCenterId]: times,
    },
  };

  const json = JSON.stringify(newWaitTimes);

  localStorage.setItem(WAIT_TIMES_KEY, json);
};