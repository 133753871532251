import React from 'react';
import EllapsedTime from './EllapsedTime';
import * as LocalCache from '../common/LocalCache';
import { Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Badge, Card, Container } from 'react-bootstrap';
import { compose } from 'recompose';

import OrderDetail from './OrderDetail';
import ArchivedOrders from './ArchivedOrders';
import { ProtectedRoute } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';
import { formatDateTime, getEtaText, StatusVariant } from './helpers';
import './OrderList.scss';

const OrderList = ({
  firebase,
  match,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [statusFilter, setStatusFilter] = React.useState();
  const [orders, setOrders] = React.useState([]);
  const [profitCenter, setProfitCenter] = React.useState({});
  const { enterpriseId } = user;
  const { profitCenterId } = match.params;

  React.useEffect(() => {
    firebase.getProfitCenterRef(enterpriseId, profitCenterId)
      .onSnapshot(snapshot => {
        setProfitCenter(snapshot.data());
        setLoading(false);
      });
  }, [firebase, enterpriseId, profitCenterId]);

  React.useEffect(() => {
    firebase.getOrdersRefByStatus(enterpriseId, profitCenterId)
      .onSnapshot(snapshot => {
        const orders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setOrders(orders);
      });
  }, [firebase, enterpriseId, profitCenterId, statusFilter]);

  React.useEffect(() => {
    const fetchWaitTimes = async () => {
      try {
        const waitTimesDoc = await firebase.getWaitTimes(enterpriseId, profitCenterId);
        const times = waitTimesDoc?.times || null;

        LocalCache.setWaitTimes(enterpriseId, profitCenterId, times);
      } catch (err) {
        console.error('Failed to fetch wait times for profit center', err);
      }
    };

    fetchWaitTimes();
  }, [firebase, enterpriseId, profitCenterId]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Container>
        <h3 className="pc">
          {profitCenter.name} 

          <Link to={`${match.url}/archive`} className="pc__options">
            <FontAwesomeIcon icon={faEllipsisV} />
          </Link>
        </h3>

        <div className="d-flex w-100 justify-content-center mt-3 mb-4">
          <div className="d-flex justify-content-center">
            <div className="preparing d-flex flex-column px-4 w-75 border-right" onClick={() => setStatusFilter('new')}>
              <h1 className="mb-0 text-center text-dark">{profitCenter.orderCountNew || 0}</h1>
              <small className="text-center text-dark">New</small>
            </div>
            <div className="preparing d-flex flex-column px-4 w-75 border-right" onClick={() => setStatusFilter('preparing')}>
              <h1 className="mb-0 text-center text-warning">{profitCenter.orderCountPreparing || 0}</h1>
              <small className="text-center text-warning">Preparing</small>
            </div>
            <div className="ready d-flex flex-column px-4 w-75" onClick={() => setStatusFilter('ready')}>
              <h1 className="mb-0 text-center text-success">{profitCenter.orderCountReady || 0}</h1>
              <small className="text-center text-success">Ready</small>
            </div>
          </div>
        </div>

        {!orders.length && <h5 className="empty-text">You don't have any new orders</h5>}

        {orders.map(o => 
          <Card
            key={o.id}
            className={`shadow-sm order ${o.status === 'new' ? 'order--new' : ''}`}
            as={Link}
            to={`${match.url}/orders/${o.id}`}
          >
            <Card.Body className="order__content">
              <div className="info">
                <p className="info__name">{o.name}</p>
                <p className="info__ordernum">
                  Order# {o.orderNumber}

                  {!o.isNew &&
                    <span className="ml-1">
                      &middot; {formatDateTime(o.updatedAt?.toDate())} &middot; {getEtaText(o.minutes)}
                    </span>
                  }
                </p>
              </div>

              <div>
                <Badge variant={StatusVariant[o.status]} className="px-2">
                  <EllapsedTime start={o?.createdAt?.toDate()} />
                </Badge>
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>

      <Switch>
        <ProtectedRoute path={`${match.path}/orders/:orderId`} component={OrderDetail} />
        <ProtectedRoute path={`${match.path}/archive`} component={ArchivedOrders} />
      </Switch>
    </>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(OrderList);