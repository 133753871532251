import React from 'react';
import { Button, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import { Toast } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';
import './ArchivedOrders.scss';

const QUERY_LIMIT = 10;

const ArchivedOrders = ({
  firebase,
  history,
  match,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [recallId, setRecallId] = React.useState();
  const [docs, setDocs] = React.useState([]);
  const [canFetch, setCanFetch] = React.useState(false);
  const [startAfter, setStartAfter] = React.useState();
  const { enterpriseId } = user;
  const { profitCenterId } = match.params;

  const gotoOrderList = () => history.push(`/profitcenters/${profitCenterId}`);

  const handleRecallOrder = async (orderId) => {
    try {
      setRecallId(orderId);

      await firebase.recallOrder(enterpriseId, profitCenterId, orderId);

      Toast({ success: 'Order recalled!'});

      gotoOrderList();
    } catch (err) {
      console.error(err);

      Toast({ error: err.message });
      setRecallId(null);
    }
  };

  const renderSnapshot = (doc) => {
    const order = { ...doc.data(), id: doc.id };

    return (
      <ListGroup.Item
        key={order.id}
        className="archive"
      >
        <div className="info">
          <p className="info__name">{order.name}</p>
          <p className="info__order">Order# {order.orderNumber}</p>
        </div>

        <div>
          <Button
            className="d-flex align-items-center"
            variant="outline-primary"
            disabled={recallId === order.id}
            onClick={() => handleRecallOrder(order.id)}
          >
            Recall {recallId === order.id && <Spinner animation="border" size="sm" className="ml-2" />}
          </Button>
        </div>
      </ListGroup.Item>
    );
  };

  React.useEffect(() => {
    const fetchOrders = async () => {
      const options = {
        limit: QUERY_LIMIT,
        startAfter,
      };

      try {
        const apiDocs = await firebase.getArchivedOrderSnapshotDocs(enterpriseId, profitCenterId, options);
        setDocs(docs => docs.slice().concat(apiDocs));
        setCanFetch(apiDocs.length === options.limit);
        setLoading(false);
      } catch (err) {
        console.error(err);
        Toast({ error: err.message });
      }

    };

    fetchOrders();
  }, [firebase, enterpriseId, profitCenterId, startAfter]);

  return(
    <Modal
      show={true}
      onHide={gotoOrderList}
    >
      <Modal.Header closeButton>
        <Modal.Title>Archived</Modal.Title>
      </Modal.Header>

      {loading && 
        <div className="text-center py-4">
          <Spinner animation="border" variant="primary" />
        </div>
      }

      {!loading && !docs.length &&
        <Modal.Body className="text-muted">
          <em>There aren't any archived orders</em>
        </Modal.Body>
      }

      {!loading && !!docs.length &&
        <>
          <ListGroup variant="flush">
            {docs.map(renderSnapshot)}

            {canFetch &&
              <ListGroup.Item
                action
                className="text-center text-primary"
                onClick={() => setStartAfter(docs[docs.length - 1])}
              >
                Load more...
              </ListGroup.Item>
            }
          </ListGroup>

          <Modal.Footer>
            <small className="text-muted">Showing latest {docs.length} orders</small>
          </Modal.Footer>
        </>
      }

    </Modal>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(ArchivedOrders);