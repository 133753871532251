import React from 'react';
import Moment from 'moment';

export const StatusVariant = {
  new: 'dark',
  preparing: 'warning',
  ready: 'success',
};

export function getEllapsedTime(startedAt) {
  const now = Moment();
  const start = Moment(startedAt);

  const diff = now.diff(start);

  return Moment.duration(diff).humanize();
}

export function getEtaText(minutes) {
  if (minutes === 0) {
    return 'Ready';
  }

  if (!minutes) {
    return <strong>New</strong>;
  }

  return `ETA ${minutes > 45 ? '45+' : minutes} min`;
}

/**
 * Format to readable date and time
 * @param {Date} date Current date and time
 */
export function formatDateTime(date) {
  if (!date) return;
  const dateString = date.toDateString();
  const [, formattedDate] = dateString.match(/\w+\s(.*)$/);
  const time = date.toLocaleTimeString();

  return `${formattedDate} ${time}`;
}
