import React from 'react';
import { withFirebase } from './firebase';

const AuthContext = React.createContext();

export const withAuth = Component => props => (
  <AuthContext.Consumer>
    {value => <Component {...props} {...value} />}
  </AuthContext.Consumer>
);

const AuthProvider = ({ children, firebase }) => {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const fetchUser = async (authUser) => {

      if (authUser) {
        const user = await firebase.getUser(authUser.uid);
        const nonAdminRoles = Object
          .keys(user.roles).filter(key => key !== 'admin')
          .map(roleId => firebase.getRole(user.enterpriseId, roleId));

        const roles = await Promise.all(nonAdminRoles);

        setUser({
          ...user,
          roles: user.roles || {},
          id: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          image: authUser.photoURL,
          profitCenters: roles.reduce((cat, role) => ({
            ...cat,
            ...role.profitCenters,
          }), {}),
        });
      } else {
        setUser(null);
      }

      setLoading(false);
    };

    const unsubscribe = firebase.auth.onAuthStateChanged(authUser => {
      fetchUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  const obj = {
    isAuthLoading: loading,
    user,
  };

  return (
    <AuthContext.Provider value={obj}>
      {children}
    </AuthContext.Provider>
  );
};

export default withFirebase(AuthProvider);