import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import config from './config';

export default class Firebase {
  constructor() {

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.functions = firebase.functions();

  }

  deleteOrder = (enterpriseId, profitCenterId, orderId) => this
    .getOrdersRef(enterpriseId, profitCenterId).doc(orderId)
    .delete();

  generateToken = () => {
    const doGenerateToken = this.functions.httpsCallable('generateToken');
    return doGenerateToken();
  };

  getEnterprise = (enterpriseId) => this
    .getEnterprisesRef().doc(enterpriseId)
    .get()
    .then(doc => doc.data());

  getEnterprisesRef = () => this.db.collection('enterprises');

  /**
   * @typedef PaginationOptions
   * @property {object} startAfter Last snapshot item to use as the starting cursor.
   * @property {number} limit Max docs to query.
   */

  /**
   * Get snapshot docs for archived orders.
   * @param {string} enterpriseId Enterprise identifier.
   * @param {string} profitCenterId Profit center identifier.
   * @param {PaginationOptions} options Pagination options.
   */
  getArchivedOrderSnapshotDocs = (enterpriseId, profitCenterId, options = {}) => {
    const { startAfter, limit } = options;

    const query = this
      .getArchivedOrdersRef(enterpriseId, profitCenterId)
      .orderBy('createdAt', 'desc');

    // This is the first query (first page)
    if (!startAfter) {
      return query
        .limit(limit)
        .get()
        .then(snapshot => snapshot.docs);
    }

    return query
      .startAfter(startAfter)
      .limit(limit)
      .get()
      .then(snapshot => snapshot.docs);
  };

  getArchivedOrdersRef = (enterpriseId, profitCenterId) => this
    .getProfitCentersRef(enterpriseId).doc(profitCenterId)
    .collection('archive');

  getOrderRef = (enterpriseId, profitCenterId, orderId) => this
    .getOrdersRef(enterpriseId, profitCenterId).doc(orderId);

  getOrdersRefByStatus = (enterpriseId, profitCenterId) => {
    let ordersRef = this
      .getOrdersRef(enterpriseId, profitCenterId)
      .orderBy('isNew', 'desc')
      .orderBy('createdAt', 'desc');

    return ordersRef;
  };

  getOrdersRef = (enterpriseId, profitCenterId) => this
    .getProfitCentersRef(enterpriseId).doc(profitCenterId)
    .collection('orders');

  getProfitCenterRef = (enterpriseId, profitCenterId) => this
    .getProfitCentersRef(enterpriseId).doc(profitCenterId);

  getProfitCenters = (enterpriseId) => this
    .getProfitCentersRef(enterpriseId)
    .get()
    .then(this.toArray);

  getProfitCentersRef = (enterpriseId) => this
    .getEnterprisesRef().doc(enterpriseId)
    .collection('profitCenters');

  getReadyTemplateKeys = async (enterpriseId, profitCenterId) => {
    const doc = await this.getSettingsRef(enterpriseId, profitCenterId).doc('message-templates').get();
    const template = doc.data();

    return Object.keys(template)
      .filter(key => key.match(/^ready_/))
      .reduce((acc, key) => ({
        ...acc,
        [key]: key
          .split('_')
          .map(word => word[0].toUpperCase() + word.slice(1))
          .join(' '),
      }), {});
  }

  getRole = (enterpriseId, roleId) => this
    .getRolesRef(enterpriseId).doc(roleId)
    .get()
    .then(doc => doc.data());

  getRolesRef = (enterpriseId) => this
    .getEnterprisesRef().doc(enterpriseId)
    .collection('roles');

  getSettingsRef = (enterpriseId, profitCenterId) => this
    .getProfitCenterRef(enterpriseId, profitCenterId)
    .collection('settings');

  getUser = (userId) => this
    .getUsersRef().doc(userId)
    .get()
    .then(doc => doc.data());

  getUsersRef = () => this.db.collection('users');

  getWaitTimes = (enterpriseId, profitCenterId) => this
    .getWaitTimesRef(enterpriseId, profitCenterId)
    .get()
    .then(doc => doc.data());

  getWaitTimesRef = (enterpriseId, profitCenterId) => this
    .getProfitCentersRef(enterpriseId).doc(profitCenterId)
    .collection('settings').doc('wait-times');

  recallOrder = async (enterpriseId, profitCenterId, orderId) => {
    const batch = this.db.batch();

    // Get the archived order
    const archiveRef = this.getArchivedOrdersRef(enterpriseId, profitCenterId).doc(orderId);
    const order = await archiveRef
      .get()
      .then(doc => doc.data());

    // Delete it
    batch.delete(archiveRef);

    // Add it to orders
    const orderRef = this.getOrderRef(enterpriseId, profitCenterId, orderId);
    batch.set(orderRef, order);

    return batch.commit();
  };

  sendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  updateOrder = async (enterpriseId, profitCenterId, orderId, order) => {
    const newOrder = {
      ...order,
      isNew: false,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await this
      .getOrdersRef(enterpriseId, profitCenterId).doc(orderId)
      .update(newOrder);

    return order;
  };

  /**
   * Helpers
   */

   toArray = (snapshot) => snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data()));
}