import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { withAuth } from '../providers';

const AnonOnlyRoute = ({
  user,
  location,
  ...props
}) => {
  if (user) {
    return <Redirect to={{ ...location, pathname: "/" }} />
  }

  return <Route {...props} />
};

export default withAuth(AnonOnlyRoute);