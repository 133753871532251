import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { Toast } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';

const ProfitCenterList = ({
  firebase,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [profitCenters, setProfitCenters] = React.useState([]);
  const { enterpriseId, profitCenters: allowedProfitCenters, roles, } = user;

  React.useEffect(() => {
    const fetchProfitCenters = async () => {
      try {
        const apiProfitCenters = await firebase.getProfitCenters(enterpriseId);
        let filteredProfitCenters = apiProfitCenters;

        if (!roles.admin) {
          filteredProfitCenters = apiProfitCenters.filter(pc => !!allowedProfitCenters[pc.id]);
        }

        setProfitCenters(filteredProfitCenters);
        setLoading(false);
      } catch (err) {
        console.error(err);
        Toast({ error: err.message });
      }
    };

    fetchProfitCenters();
  }, [firebase, enterpriseId, allowedProfitCenters, roles]);

  if (loading) {
    return null;
  }

  return (
    <Container className="my-4">
      <h1>Profit Centers</h1>

      {!profitCenters.length &&
        <em>You don't have any stores assigned. Contact your admin to update your roles.</em>
      }

      <ListGroup variant="flush">
        {profitCenters.map(pc =>
          <ListGroup.Item
            action
            className="d-flex justify-content-between align-items-center"
            key={pc.id}
            as={Link}
            to={`/profitcenters/${pc.id}`}
          >
            <div>
              <h5>{pc.name}</h5>
              <div className="text-muted">{pc.propertyName}</div>
            </div>

            <div>
              <h3 className="mb-0 text-center text-primary">{pc.orderCountNew || 0}</h3>
              <small className="text-muted">New</small>
            </div>
          </ListGroup.Item>
        )}
      </ListGroup>

    </Container>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(ProfitCenterList);