import React from 'react';
import PropTypes from 'prop-types';
import { getEllapsedTime } from './helpers';

const EllapsedTime = ({
  start,
}) => {
  const [ellapsed, setEllapsed] = React.useState(getEllapsedTime(start));

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setEllapsed(getEllapsedTime(start));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [start]);

  return <span>{ellapsed}</span>;
};

EllapsedTime.propTypes = {
  start: PropTypes.object.isRequired,
};

export default EllapsedTime;