import React from 'react';
import styled from 'styled-components';
import { Alert, Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { Toast } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';

const maskedToken = '••••••••••••••••••••';

const Control = styled.div`
  background: rgba(0, 0, 0, .2);
  padding: 1rem;
`;

const Admin = ({
  firebase,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [working, setWorking] = React.useState(false);
  const [token, setToken] = React.useState(maskedToken);
  const [showMessage, setShowMessage] = React.useState(false);
  const { enterpriseId } = user;

  const generateToken = async () => {
    try {
      setWorking(true);
      const result = await firebase.generateToken();
      setToken(result.data.token);
      setShowMessage(true);
    } catch (err) {
      Toast({ error: err.message });
    }

    setWorking(false);
  };

  React.useEffect(() => {
    const fetchEnterprise = async () => {
      try {
        const enterprise = await firebase.getEnterprise(enterpriseId);
        const curToken = enterprise.tokenGenerated ? maskedToken : '';

        setToken(curToken);
        setLoading(false);
      } catch (err) {
        console.error(err);
        Toast({ error: err.message });
      }
    };

    fetchEnterprise();
  }, [firebase, enterpriseId]);

  if (loading) {
    return null;
  }

  return (
    <Container className="my-4">
      <Card>
        <Card.Body>
          <h3>API Token</h3>
          <p>Generate token to post orders through the API</p>

          {showMessage && 
            <Alert variant="success" className="animate__animated animate__fadeIn">
              <FontAwesomeIcon icon={faCheckCircle} /> For security, this will only be shown at this time.
            </Alert>
          }

          <Form.Group>
            <Control className="rounded">
              {token}
            </Control>
          </Form.Group>

          <Button onClick={generateToken} disabled={working} className="d-flex align-items-center">
            Generate {working && <Spinner animation="border" size="sm" className="ml-2" /> }
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(Admin);