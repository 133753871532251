import React from 'react';
import * as Yup from 'yup';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { Link } from 'react-router-dom';
import { Messaging } from '../common/images';
import { withFirebase } from '../common/providers';
import './SignIn.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
  password: Yup.string()
    .required('Password is required'),
});

const SignIn = ({
  firebase
}) => {
  const [error, setError] = React.useState();

  const handleSignIn = async ({ email, password }) => {
    try {
      await firebase.signInWithEmailAndPassword(email, password);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signin">
      <Row noGutters className="h-100">
        <Col md={6} className="signin__left d-none d-md-flex d-lg-flex d-xl-flex">
          <div className="promo">
            <h3 className="promo__text">Messaging done right</h3>
            <img src={Messaging} alt="messaging" className="promo__img" />
          </div>
        </Col>

        <Col md={6} className="signin__right">
          <div className="controls">
            <h3 className="controls__title">Sign In</h3>

            {error &&
              <Alert variant="danger">
                <FontAwesomeIcon icon={faTimesCircle} /> {error || 'Error'}
              </Alert>
            }

            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={SignInSchema}
              onSubmit={handleSignIn}
            >
              {({ errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>

                <FormikForm>

                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      autoFocus={true}
                      autoComplete="username"
                      value={values.email}
                      isInvalid={!!touched.email && !!errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={values.password}
                      isInvalid={!!touched.password && !!errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Col xl={3}>
                      <Button
                        block
                        type="submit"
                        disabled={isSubmitting || !isValid}
                      >
                        Sign In
                      </Button>
                    </Col>
                    <Col xl={9} className="controls__forgot">
                      <Link to="/reset">Forgot Password</Link>
                    </Col>
                  </Row>
                </FormikForm>

              }
            </Formik>

          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withFirebase(SignIn);