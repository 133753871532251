import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AnonOnlyRoute, MainNav, ProtectedRoute } from './common/components';
import { withAuth } from './common/providers';
import { Admin, ResetPassword, SignIn } from './account';
import { ProfitCenterList } from './profitcenters'
import { OrderList } from './orders';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';
import './App.scss';

function App({
  isAuthLoading,
  user
}) {
  if (isAuthLoading) {
    return null;
  }

  return (
    <>

      <ToastContainer
        autoClose={2500}
        closeButton={false}
        hideProgressBar={true}
        position="bottom-left"
        bodyClassName="otp-toast"
      />

      <Router>

        {user && <MainNav />}
  
        <Switch>
          <ProtectedRoute exact path="/" component={ProfitCenterList} />
          <ProtectedRoute path="/admin" component={Admin} />
          <AnonOnlyRoute path="/signin" component={SignIn} />
          <Route path="/reset" component={ResetPassword} />
          <ProtectedRoute path="/profitcenters/:profitCenterId" component={OrderList} />
          <Redirect path="*" to="/" />
        </Switch>

      </Router>
    </>
  );
}

export default withAuth(App);