import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { Toast } from '../common/components';
import { withFirebase } from '../common/providers';
import { Link } from 'react-router-dom';

const Page = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--dark);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled(Card)`
  width: 600px;
  max-width: 600px;
  margin: 1rem;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email')
});

const ResetPassword = ({
  firebase,
}) => {

  const handleSubmit = async ({ email }) => {
    try {
      await firebase.sendPasswordResetEmail(email);
      Toast({ success: 'Email sent!' });
    } catch (err) {
      console.error(err);
      Toast({ error: err.message });
    }
  };

  return (
    <Page>
      <Box>
        <Card.Body>
          <h3>Password Reset</h3>
          <p>Enter your email and we will send you a reset link</p>

          <Formik
            initialValues={{ email: '' }}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>

              <FormikForm>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    autoFocus={true}
                    isInvalid={!!errors.email && !!touched.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <ButtonRow>
                  <Button
                    className="d-flex align-items-center"
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Submit {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                  </Button>

                  <Button variant="link" as={Link} to="/signin">Sign In</Button>
                </ButtonRow>

              </FormikForm>

            }
          </Formik>
        </Card.Body>
      </Box>
    </Page>
  );
};

export default withFirebase(ResetPassword);