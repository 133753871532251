import React from 'react';
import Firebase from './Firebase';

const FirebaseContext = React.createContext();

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {value => <Component {...props} {...value} />}
  </FirebaseContext.Consumer>
);

export default ({ children }) => (
  <FirebaseContext.Provider value={{ firebase: new Firebase() }}>
    {children}
  </FirebaseContext.Provider>
);