import React from 'react';
import phoneNumber from 'libphonenumber-js';
import EllapsedTime from './EllapsedTime';
import * as LocalCache from '../common/LocalCache';
import { formatDateTime, getEtaText, StatusVariant } from './helpers';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import { Toast } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import './OrderDetail.scss';

const WAIT_TIMES = [
  { name: '15 minutes', value: 15 },
  { name: '30 minutes', value: 30 },
  { name: '45 minutes', value: 45 },
  { name: '45+ minutes', value: 60 },
];

const OrderDetail = ({
  firebase,
  history,
  match,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [loadingWaitTimes, setLoadingWaitTimes] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [order, setOrder] = React.useState({});
  const [show, setShow] = React.useState(true);
  const [waitTimes, setWaitTimes] = React.useState(WAIT_TIMES);
  const [readyKeys, setReadyKeys] = React.useState({});
  const { enterpriseId } = user;
  const { profitCenterId, orderId } = match.params;

  const handleDelete = async () => {
    try {
      setDeleting(true);

      await firebase.deleteOrder(enterpriseId, profitCenterId, orderId);

      handleGotoList();

      Toast({ error: 'Order archived!', icon: faTrash });
    } catch (err) {
      console.error(err);

      Toast({ error: err.message });
      setDeleting(false);
    }
  };

  const handleGotoList = () => history.push(`/profitcenters/${profitCenterId}`);

  const handleSend = async (minutes, readyKey) => {
    try {
      setSending(true);

      const newOrder = {
        minutes,
        status: minutes === 0 ? 'ready' : 'preparing',
        templateKey: readyKey || '',
      };

      await firebase.updateOrder(enterpriseId, profitCenterId, orderId, newOrder);

      Toast({ success: 'Notification sent!' });
    } catch (err) {
      console.error(err);
      Toast({ error: err.message });
    }

    setSending(false);
  };

  React.useEffect(() => {
    firebase.getOrderRef(enterpriseId, profitCenterId, orderId)
      .onSnapshot(snapshot => {
        const apiOrder = snapshot.data();

        if (apiOrder) {
          setOrder(apiOrder);
        }

        setLoading(false);
      });
  }, [firebase, enterpriseId, profitCenterId, orderId]);

  React.useEffect(() => {
    const times = LocalCache.getWaitTimes(enterpriseId, profitCenterId);

    if (times) {
      setWaitTimes(times);
    }

    setLoadingWaitTimes(false);
  }, [firebase, enterpriseId, profitCenterId]);

  React.useEffect(() => {
    const getReadyKeys = async () => {
      try {
        const curReadyKeys = await firebase.getReadyTemplateKeys(enterpriseId, profitCenterId);
        setReadyKeys(curReadyKeys);
      } catch (err) {
        console.error(err);
      }
    };

    getReadyKeys();
  }, [firebase, enterpriseId, profitCenterId]);

  if (!loading && !order.name) {
    Toast({ error: 'Order not found' });
    return <Redirect to={`/profitcenters/${profitCenterId}`} />;
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      onExited={handleGotoList}
    >
      {loading &&
        <Modal.Body className="text-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      }

      {!loading && !!order &&
        <Modal.Body className="px-0 py-4">
          <div className="detail">
            <h5 className="detail__title">
              Order# {order.orderNumber}
              <span className="detail__delete" onClick={handleDelete}>
                {!deleting && <FontAwesomeIcon icon={faTrash} />}
                {deleting && <Spinner animation="border" size="sm" />}
              </span>
            </h5>
            <h1 className={`detail__ellapsed text-${StatusVariant[order.status]}`}>
              <EllapsedTime start={order.createdAt?.toDate()} />
            </h1>
            <small className="detail__updated">Updated {formatDateTime(order.updatedAt?.toDate())} &middot; {getEtaText(order.minutes)}</small>
          </div>

          <hr className="my-4" />

          <div className="px-4">

            <div className="sms">
              <div className="sms__user">
                <div className="sms__avatar">{order.name[0]}</div>

                <div className="sms__info">
                  <div className="sms__name">{order.name}</div>
                  <div className="sms__phone">{phoneNumber(order.phone.toString(), order.countryCode || 'US').formatInternational()}</div>
                </div>
              </div>

              <div className="sms__btn">
                <Dropdown>
                  <Dropdown.Toggle disabled={sending || loadingWaitTimes}>
                    {(!sending && !loadingWaitTimes) && <FontAwesomeIcon icon={faPaperPlane} />}
                    {(sending || loadingWaitTimes) && <Spinner animation="border" size="sm" />}
                  </Dropdown.Toggle>

                  {!loadingWaitTimes &&
                    <Dropdown.Menu>
                      {waitTimes.map((time, index) =>
                        <Dropdown.Item key={index} onClick={() => handleSend(time.value)}>{time.name}</Dropdown.Item>
                      )}

                      <Dropdown.Item onClick={() => handleSend(0)}>Ready</Dropdown.Item>

                      {!!Object.keys(readyKeys).length && Object.keys(readyKeys).map(key =>
                        <Dropdown.Item key={key} onClick={() => handleSend(0, key)}>{readyKeys[key]}</Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  }

                </Dropdown>
              </div>
            </div>

          </div>
        </Modal.Body>
      }
    </Modal>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(OrderDetail);